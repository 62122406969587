<template>
    <div class="location-container">
        <div class="location-name">{{ title }}</div>
        <div class="address-text"><p><slot/></p></div>
    </div>
</template>

<script>
    export default {
        name: "LocationAddress",
        props: ['title']
    }
</script>

<style scoped>
    .location-container {
        display: flex;
        flex-direction: column;
    }
    .location-name {
        font-weight: bold;
        text-transform: uppercase;
    }
    .address-text {
        font-size: 0.85rem;
    }
    .address-text p {
        margin: 0;
    }
</style>
