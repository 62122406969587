<template>
    <div>
        <b-container>
            <b-row>
                <b-col md="7">
                    <h3>Vilka är vi?</h3>
                    <p>
                        Vi på Rato AB har varit verksamma sedan 2007 och har lång och bred erfarenhet av alla olika typer av byggarbeten och fastighetsskötsel.<br>
                        <br>
                        Rato AB arbetar till största del mot kommersiella uppdragsgivare men vi har även stor erfarenhet av att hjälpa dig som är i behov av mindre bygg eller service tjänster.<br>
                        <br>
                        Har du en fråga eller är i behov av rådgivning inom bygg eller fastighet kontakta oss så kommer vi gärna ut och besöker ditt byggprojekt.
                    </p>
                </b-col>
                <b-col md="5">
                    <contact-form/>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import ContactForm from "../ContactForm";
    export default {
        name: "AboutUsContact",
        components: {ContactForm}
    }
</script>

<style scoped>
</style>
