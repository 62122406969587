<template>
    <b-card class="contact-card">
        <b-container>
            <b-row align-v="center">
                <b-col cols="12" class="mb-3">
                    <h5 class="contact-title">Kontakta Oss</h5>
                </b-col>
                <b-col cols="12">
                    <b-row align-h="center">
                        <b-form action="https://mailthis.to/nicklas@ratoab.se"
                                name="contact-us"
                                method="post">

                            <input type="hidden" name="_subject" value="New message from Rato AB!">
                            <input type="hidden" name="_after" :value="afterSubmit">
                            <input type="hidden" name="_honeypot" value="">
                            <input type="hidden" name="_confirmation" value="">

                            <b-row>
                                <b-col cols="12" class="pb-3" v-if="isSent === '1'">
                                    <b-alert class="email-alert" show>
                                        Ditt meddelande har skickats!<br>
                                        Vi kommer att kontakta dig inom kort.
                                    </b-alert>
                                </b-col>
                                <b-col cols="6" md="12" lg="6" class="pb-3">
                                    <label class="sr-only" for="name">Namn</label>
                                    <b-input
                                            id="name"
                                            name="name"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            placeholder="Namn"
                                            required
                                    ></b-input>
                                </b-col>

                                <b-col cols="6" md="12" lg="6" class="pb-3">
                                    <label class="sr-only" for="phone">Telefon</label>
                                    <b-input
                                            id="phone"
                                            name="phone"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            placeholder="Telefon"
                                            type="tel"
                                            required
                                    ></b-input>
                                </b-col>

                                <b-col cols="12" class="pb-3">
                                    <label class="sr-only" for="email">E-post</label>
                                    <b-input
                                            id="email"
                                            name="email"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            placeholder="E-post"
                                            type="email"
                                            required
                                    ></b-input>
                                </b-col>

                                <b-col cols="12" class="pb-3">
                                    <label class="sr-only" for="message">Meddelande</label>
                                    <b-textarea
                                            id="message"
                                            name="message"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            placeholder="Meddelande"
                                            rows="5"
                                            required
                                    ></b-textarea>
                                </b-col>

                                <b-col cols="12" class="pb-3">
                                    <b-checkbox
                                            id="tos-checkbox"
                                            name="tos-checkbox"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            required>
                                        <div class="checkbox-text">Genom att skicka detta meddelande samtycker du till att vi tar del av de personuppgifter du valt att delge.</div>
                                    </b-checkbox>
                                </b-col>

                                <b-col cols="12">
                                    <b-button type="submit" class="default-button" style="width: 100%">SKICKA</b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </b-card>
</template>

<script>
    const urlParams = new URLSearchParams(window.location.search);

    export default {
        name: "ContactForm",
        methods: {
            addUrlParam: function(search, key, val){
                let newParam = key + '=' + val,
                    params = '?' + newParam;

                // If the "search" string exists, then build params from it
                if (search) {
                    // Try to replace an existance instance
                    params = search.replace(new RegExp('([?&])' + key + '[^&]*'), '$1' + newParam);

                    // If nothing was replaced, then add the new param to the end
                    if (params === search) {
                        params += '&' + newParam;
                    }
                }

                return params;
            }
        },
        data () {
            return {
                afterSubmit: document.location.href + this.addUrlParam(window.location.search, 'sent', '1'),
                isSent: urlParams.get('sent')
            }
        },
        computed: {

        }
    }
</script>

<style scoped>
    .contact-card {
        box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.12);
        border: none;
    }

    .contact-title {
        color: var(--default-text-color);
        text-transform: uppercase;
        text-align: center;
    }

    .checkbox-text {
        font-size: 0.75rem;
    }

    .email-alert {
        background: #37C894;
        color: white;
        text-align: center;
        border-radius: 0;
        border: none;
    }

</style>
