<template>
    <footer>
        <div class="container">
            <b-row>
                <div class="location col-md-8">
                    <div class="row text-center text-md-left">
                        <b-col md="4">
                            <location-address title="Rato Ab" class="location">
                                Org.nr 556742-4733<br>
                                <br>
                                Rissneleden 142, 5tr<br>
                                174 57 Sundbyberg
                            </location-address>
                        </b-col>
                        <b-col md="8">
                            <location-address title="Kontakta Oss" class="location">
                                <b-container>
                                    <b-row>
                                        <b-col md="6" style="padding: 0" class="mb-4 mb-md-0">
                                            Arbetsledare: Nicklas Bejhem<br>
                                            <br>
                                            Telefon: <a href="tel:+46734444003" class="link">+46 73 444 4003</a><br>
                                            Epost: <a href="mailto:nicklas@ratoab.se" class="link">nicklas@ratoab.se</a>
                                        </b-col>
                                        <b-col md="6" style="padding: 0">
                                            Arbetsledare: Hasse Azad<br>
                                            <br>
                                            Telefon: <a href="tel:+46705891111" class="link">+46 70 589 11 11</a><br>
                                            Epost: <a href="mailto:hasse@ratoab.se" class="link">hasse@ratoab.se</a>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </location-address>
                        </b-col>
                    </div>
                </div>
                <b-col md="4" class="copyright">
                    <div class="float-md-right d-flex flex-column align-items-center">
                        <social-buttons/>
                        <span class="pt-2">&copy; 2020 RATO AB</span>
                    </div>
                </b-col>
            </b-row>
        </div>
    </footer>
</template>

<script>
    import LocationAddress from "./LocationAddress";
    import SocialButtons from "./SocialButtons";

    export default {
        name: "TheFooter",
        components: {SocialButtons, LocationAddress}
    }
</script>

<style scoped>
    footer {
        background: var(--primary-color);
        color: white;
        padding: 2% 7%;
    }

    .location {
        margin-bottom: 2.5vh;
        margin-top: 0.5vh;
    }

    .link {
        text-decoration: none;
        color: white;
    }

    @media (min-width: 768px) {
        .location {
            margin: 0;
        }
    }
</style>
