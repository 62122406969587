<template>
    <div>
        <b-navbar id="header" toggleable="md" type="light" fixed="top">
            <b-navbar-brand to="/"><img src="../assets/logo.png" class="logo" alt="logo"></b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item class="nav-item" exact-active-class="active" to="/">Hem</b-nav-item>
                    <b-nav-item-dropdown text="VÅRA TJÄNSTER">
                        <b-dropdown-item to="/services/commercial">KOMMERSIELL</b-dropdown-item>
                        <b-dropdown-item to="/services/private">Privat</b-dropdown-item>
                        <b-dropdown-item to="/services/maintenance">Fastighetsskötsel</b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-nav-item class="nav-item" active-class="active" to="/about">OM OSS</b-nav-item>
                    <b-nav-item class="nav-item" active-class="active" to="/references">Referenser</b-nav-item>
                    <b-nav-item class="nav-item" active-class="active" to="/contact">KONTAKTA OSS</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        name: "TheNavbar"
    }
</script>

<style scoped>
    .logo {
        height: 50px;
    }
    .nav-item {
        font-weight: 600;
        font-size: 0.9rem;
        text-transform: uppercase;
    }
    .active {
        color: var(--primary-color) !important;
    }
    .navbar-nav > li{
        padding-left:10px;
        padding-right:10px;
    }
    .navbar {
        transition: all 0.25s ease-out 0s;
        background: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 99;
    }
    @media (min-width: 768px) {
        .navbar {
            transition: all 0.25s ease-out 0s;
            background: #fff;
            z-index: 99;
        }
    }
</style>
