<template>
    <b-container>
        <b-row>
            <b-col md="4" class="mb-4">
                <img src="https://api.ratoab.se/HeroImages?location=0" alt="Kommersiell" class="img-fluid mb-3 service-image">
                <h3 class="mb-3">Kommersiell</h3>
                <p class="description">Rato har stor erfarenhet med att arbeta mot kommersiella kunder. Cirka 95 % av vår verksamhet är inom detta område.</p>
                <ul class="services mb-3">
                    <li>Klarar stora projekt.</li>
                    <li>Rätt certifikat.</li>
                    <li>Kostnadseffektiva.</li>
                </ul>
                <b-button class="default-button" to="/services/commercial">LÄS MER</b-button>
            </b-col>
            <b-col md="4" class="mb-4">
                <img src="https://api.ratoab.se/HeroImages?location=1" alt="Privat" class="img-fluid mb-3 service-image">
                <h3 class="mb-3">Privat</h3>
                <p class="description">Vi hjälper även många privata kunder att förverkliga små som stora projekt, allt från hemma renoveringar till andra arbeten.</p>
                <ul class="services mb-3">
                    <li>Kundfokuserade.</li>
                    <li>Korrekt behörighet.</li>
                    <li>Snabba & Effektiva.</li>
                </ul>
                <b-button class="default-button" to="/services/private">LÄS MER</b-button>
            </b-col>
            <b-col md="4" class="mb-4">
                <img src="https://api.ratoab.se/HeroImages?location=2" alt="Fastighetsskötsel" class="img-fluid mb-3 service-image">
                <h3 class="mb-3">Fastighetsskötsel</h3>
                <p class="description">Förutom byggjobb hjälper vi även stora som små aktörer med all typ av fastighetsskötsel så ni kan tänka på annat.</p>
                <ul class="services mb-3">
                    <li>Fast som löpande.</li>
                    <li>Noggranna.</li>
                    <li>Lyhörda.</li>
                </ul>
                <b-button class="default-button" to="/services/maintenance">LÄS MER</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Services"
    }
</script>

<style scoped>
    .services {
        list-style:none;
        margin: 0;
        padding: 0;
    }

    .services li {
        background: url('../../assets/icons/check.svg') no-repeat left top;
        background-size: 20px;
        height: 20px;
        padding-left: 40px;
        padding-bottom: 30px;
        font-weight: bold;
        color: var(--primary-color);
    }

    .service-image {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
</style>
