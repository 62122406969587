<template>
    <div class="headline">
        <b-container>
            <b-row align-h="center">
                <b-col cols="1">
                    <div class="line"/>
                </b-col>
                <b-col cols="10">
                    <div class="content">
                        <b-row>
                            <h6>{{title}}</h6>
                        </b-row>
                        <b-row>
                            <span class="body-content">{{body}}</span>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "Headline",
        props: ['title', 'body']
    }
</script>

<style scoped>
    .headline {
        margin-top: 2vh;
        margin-bottom: 2vh;
    }

    .line {
        border-right: 3px solid var(--primary-color);
        height: 100%;
    }

    .content {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .content h6 {
        color: var(--primary-color);
    }

    .body-content {
        font-size: 1.8rem;
        font-weight: bold;
    }
</style>
