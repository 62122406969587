import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import config from 'config'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: 'Rato AB',
            metaTags: [
                {
                    property: 'og:image',
                    content: `${config.siteUrl}${require('../assets/images/apartment-architectural-design-architecture-building.jpg')}`
                },
            ]
        },
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue'),
        meta: {
            title: 'Om Oss | Rato AB',
            metaTags: [
                {
                    property: 'og:image',
                    content: `${config.apiUrl}/HeroImages?location=3`
                },
            ]
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue'),
        meta: {
            title: 'Kontakta Oss | Rato AB',
            metaTags: [
                {
                    property: 'og:image',
                    content: `${config.apiUrl}/HeroImages?location=4`
                },
            ]
        }
    },
    {
        path: '/references',
        name: 'Referenser',
        component: () => import('../views/Gallery.vue'),
        meta: {
            title: 'Referenser | Rato AB',
            metaTags: [
                {
                    property: 'og:image',
                    content: `${config.siteUrl}${require('../assets/images/kitchen-design-modern-contemporary.jpg')}`
                },
            ]
        }
    },
    {
        path: '/services/commercial',
        name: 'Commercial',
        component: () => import('../views/Services/Commercial.vue'),
        meta: {
            title: 'Kommersiell | Rato AB',
            metaTags: [
                {
                    property: 'og:image',
                    content: `${config.apiUrl}/HeroImages?location=0`
                },
            ]
        }
    },
    {
        path: '/services/private',
        name: 'Private',
        component: () => import('../views/Services/Private.vue'),
        meta: {
            title: 'Privat | Rato AB',
            metaTags: [
                {
                    property: 'og:image',
                    content: `${config.apiUrl}/HeroImages?location=1`
                },
            ]
        }
    },
    {
        path: '/services/maintenance',
        name: 'Maintenance',
        component: () => import('../views/Services/Maintenance.vue'),
        meta: {
            title: 'Fastighetsskötsel | Rato AB',
            metaTags: [
                {
                    property: 'og:image',
                    content: `${config.apiUrl}/HeroImages?location=2`
                },
            ]
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
            title: 'Logga In | Rato AB',
        }
    },
    {
        path: '/admin',
        name: 'Admin Page',
        redirect: '/admin/main',
        component: () => import('../views/Admin.vue'),
        children: [
            {path: 'main', component: () => import('../components/Admin/MainPage')},
            {path: 'references', component: () => import('../components/Admin/GalleryPage')},
            {path: 'commercial', component: () => import('../components/Admin/Services/CommercialPage')},
            {path: 'private', component: () => import('../components/Admin/Services/PrivatePage')},
            {path: 'maintenance', component: () => import('../components/Admin/Services/MaintenancePage')},
            {path: 'about', component: () => import('../components/Admin/AboutPage')},
            {path: 'contact', component: () => import('../components/Admin/ContactPage')},
            {path: 'change-password', component: () => import('../components/Admin/ChangePassword')},
        ],
        meta: {
            title: 'Admin | Rato AB'
        }
    },
    {
        path: '/gallery',
        redirect: '/references',
        component: () => import('../views/Gallery.vue')
    },
    {
        path: '*',
        component: () => import('../views/Home.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const adminPages = ['/admin'];
    const authRequired = adminPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
})

export default router
