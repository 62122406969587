<template>
  <div id="app">
    <the-navbar/>
    <vue-page-transition name="fade">
      <router-view/>
    </vue-page-transition>
    <the-footer/>
  </div>
</template>


<style>
#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
  import TheFooter from "./components/Footer/TheFooter";
  import TheNavbar from "./components/TheNavbar";
  export default {
    components: {TheNavbar, TheFooter}
  }
</script>
