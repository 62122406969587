<template>
    <div class="hero">
        <b-row class="hero-row" align-v="center" align-h="center">

            <div class="carousel" v-if="type !== 'carousel'">
                <div class="overlay" v-if="darkBackground"/>
                <img :src="image" class="image-static" alt="Header"/>
            </div>

            <carousel
                    class="carousel"
                    v-if="type === 'carousel'"
                    :perPage="1"
                    :autoplay="true"
                    :loop="true"
                    :autoplayTimeout="timeout">

                <slide v-for="image in images" :key="image.url" style="height: 100%">
                    <div class="description">
                        <div class="title" v-if="image.title">{{ image.title }}</div>
                        <div class="body" v-if="image.description">{{ image.description }}</div>
                    </div>
                    <div class="overlay" v-if="darkBackground"/>
                    <img :src="image.url" class="image" :alt="image.title"/>
                </slide>

            </carousel>

            <b-col cols="8" class="item" style="text-align: center">
                <slot/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: "Hero",
        props: {
            'darkBackground': {
                type: Boolean,
                default: true
            },
            'type': String,
            'image': String,
            'images': Array,
            'timeout': Number
        },
        components: {
            Carousel,
            Slide
        }
    }
</script>

<style scoped>
    .hero {
        height: 90vh;
        overflow-x: hidden;
    }

    .hero-row {
        width: 100vw;
        height: 100%;
        margin: auto;
    }

    .item {
        position: absolute;
        z-index: 3;
        color: white;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .carousel {
        height: 100%;
        width: 100%;
    }

    .image {
        height: 100vh;
        width: 100vw;
        object-fit: cover;
    }

    .image-static {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        background: rgba(0, 0, 0, 0.40);
    }

    .description {
        position: absolute;
        z-index: 3;
        bottom: 0;
        margin-left: 3vw;
        margin-bottom: 20px;
        color: white;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 100%;
    }

    .description > .title {
        font-weight: bold;
        text-transform: uppercase;
    }

    .description > .body {
        font-size: 2rem;
        font-weight: bold;
    }
</style>
