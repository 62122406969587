<template>
  <div class="home">
    <hero
            type="carousel"
            :timeout="5000"
            :images="imageList"
    >

      <h1 class="heroText">Vi utför alla typer av byggjobb inom både privat och kommersiell verksamhet</h1><br>
      <b-button class="default-button mt-2" to="/contact">Kontakta Oss</b-button>
    </hero>

    <headline
            title="Våra tjänster"
            body="Vi har bred erfarenhet av alla typer av byggjobb och fastighetsskötsel."
            class="mb-5 mt-5"
    />

    <services class="mb-5 mt-5"/>

    <about-section class="mb-5 mt-5"/>

    <certifications class="mb-5 mt-5"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "../components/Hero";
import Headline from "../components/Headline";
import AboutSection from "../components/Home/AboutSection";
import Certifications from "../components/Home/Certifications";
import Services from "../components/Home/Services";
import axios from 'axios';
import config from 'config';

export default {
  name: 'Home',
  components: {
    Services,
    Certifications,
    AboutSection,
    Headline,
    Hero
  },
  data () {
    return {
      imageList: [{
        'url': require('../assets/images/apartment-architectural-design-architecture-building.jpg')
      }]
    }
  },
  mounted () {
    window.scrollTo(0, 0);

    axios.get(`${config.apiUrl}/carousel/main`)
            .then(response => {
              this.imageList.length = 0;

              response.data.forEach(e => {
                this.imageList.push({
                  'url': `https://api.ratoab.se${e.img_path}`,
                  // 'title': e.img_title,
                  // 'description': e.img_caption
                });
              })
            });
  }
}
</script>

<style scoped>
  .heroText {
    font-size: 2rem;
    text-transform: uppercase;
  }
</style>
