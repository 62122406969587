<template>
    <div>
        <b-row align-v="center">
            <b-col>
                <a href="https://instagram.com/rato_ab_sthlm" target="_blank">
                    <img src="@/assets/icons/instagram.svg" alt="Instagram" class="icon"/>
                </a>
            </b-col>
            <b-col>
                <a href="mailto:nicklas@ratoab.se">
                    <img src="@/assets/icons/email.svg" alt="Email" class="icon"/>
                </a>
            </b-col>
            <b-col style="display: none">
                <a href="#" target="_blank">
                    <img src="@/assets/icons/facebook.svg" alt="Facebook" class="icon"/>
                </a>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "SocialButtons"
    }
</script>

<style scoped>
    .icon {
        width: 36px;
        max-height: 36px;
    }
</style>
