<template>
    <div>
        <b-container>
            <b-row align-h="center">
                <h3>Våra Certifieringar</h3>
                <b-col cols="12" class="mt-5">
                    <b-row align-v="center">
                        <b-col cols="4" class="item">
                            <img src="@/assets/images/qhytn08zi4whfngmmxpm.jpg" alt="AAA" class="img-fluid certification-logo">
                        </b-col>
                        <b-col cols="4" class="item">
                            <img src="@/assets/images/di_gasell_new2015_FB.png" alt="Di Gasell" class="img-fluid certification-logo">
                        </b-col>
                        <b-col cols="4" class="item">
                            <img src="@/assets/images/logotyp-per.jpg" alt="PER" class="img-fluid certification-logo">
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "Certifications"
    }
</script>

<style scoped>
    .item {
        text-align: center;
        margin-bottom: 40px;
    }

    .certification-logo {
        width: 150px;
        max-height: 150px;
    }
</style>
